import {JSX} from 'react';
import {Layout} from '../components/Layout';
import {DEFAULT_CONTEXT} from '../helpers/context/room-metadata-context';
import {DEFAULT_FLAGS} from '../lib/flag-fetcher';
import {GamePlayer} from '../components/GamePlayer';

const IndexPage = (): JSX.Element => (
  <Layout
    featureFlagsProviderValue={DEFAULT_FLAGS}
    roomMetadataProviderValue={DEFAULT_CONTEXT}
  >
    <GamePlayer
      propertyName=""
      bookableRoomName=""
      useAutomaticFailover={false}
      showRFPFrontend={false}
      enableUIContainer={false}
    />
  </Layout>
);

export default IndexPage;
